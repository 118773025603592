angular
  .module('app')
  .controller('AccountController', AccountController);

function AccountController($ionicPopup, $state, userData, loading) {
  var vm = this;
  vm.user = userData;
  vm.password = "";
  vm.save = save;

  function save() {
    if (vm.password) vm.user.password = vm.password;

    loading.show();

    vm.user
      .save()
      .then(function() {
        loading.hide();

        return $ionicPopup.alert({
          title: 'Success',
          template: 'Your account has been updated.'
        });
      })
      .then(function() {
        $state.reload();
      })
      .catch(function() {
        loading.hide();

        $ionicPopup.alert({
          title: 'Error',
          template: 'There was an error updating your account.'
        });
      });
  }
}