angular
  .module('app')
  .controller('LoginController', LoginController);

function LoginController($scope, $window, $state, $ionicHistory, $ionicPopup, auth, loading) {
  var vm = this;
  vm.username = auth.lastLogin || '';
  vm.password = '';
  vm.submit = submit;
  vm.openForgotPasswordPage = openForgotPasswordPage;
  vm.openCompatibilitySite = openCompatibilitySite;

  $scope.$on('$ionicView.enter', clearCache);

  function clearCache() {
    $ionicHistory.clearCache();
  }

  function submit(username, password) {
    loading.show();
    auth.login(username, password)
    .then(function() {
      $state.go('menu.cameras');
    })
    .catch(function(err) {
      if (err.code === "invalid_credentials") {
        displayInvalidCredentialsError(err);
      } else if (err.code === "acb_plus_not_supported") {
        openCompatibilitySite(err.token);
      } else if (err.code === "acb_plus_unauthorized") {
        displayErrorUnauthorized(err);
      } else if (err.code === "private_browsing") {
        displayPrivateBrowsingError(err);
      }else {
        displayGenericError(err);
      }
    })
    .finally(loading.hide);
  }

  function displayInvalidCredentialsError(error) {
    $ionicPopup.show({
      title: "Unable to Login",
      template: error.message,
      buttons: [{
        text: 'OK',
        type: ['button-positive', 'button-block']
      }, {
        text: 'Forgot Password?',
        type: ['button-default', 'button-block'],
        onTap: function(e) {
          openForgotPasswordPage();
        }
      }]
    });
  }

  function displayErrorUnauthorized(error) {
    $ionicPopup.show({
      title: "Upgrade Alert",
      templateUrl: "templates/acb-plus-unauthorized.html",
      buttons: [{
        text: 'Learn More',
        type: ['button-positive', 'button-block'],
        onTap: function(e) {
          openAcbUpgradePage(error.token);
        }
      }, {
        text: 'Maybe Later',
        type: ['button-stable', 'button-block'],
        onTap: function(e) {
          openCompatibilitySite(error.token);
        }
      }]
    });
  }

  function displayPrivateBrowsingError(error) {
    var platform = ionic.Platform.platform();
    var helpLinks = {
      ios: 'https://support.apple.com/en-us/HT203036',
      macintel: 'https://support.apple.com/kb/PH19216?locale=en_US'
    };
    var buttons = [{
        text: 'OK',
        type: ['button-block', 'button-positive']
    }];

    if (_.contains(Object.keys(helpLinks), platform)) {
      buttons.unshift({
        text: 'How do I turn off Private Browsing?',
        type: ['button-block', 'button-stable'],
        onTap: function(e) {
          $window.open(helpLinks[platform], '_blank');
        }
      });
    }

    $ionicPopup.show({
      title: "Private Browsing Alert",
      templateUrl: "templates/private-browsing-error.html",
      buttons: buttons
    });
  }

  function displayGenericError(error) {
    $ionicPopup.alert({
      title: "Unable to Login",
      template: error.message,
      okType: ['button-block', 'button-positive']
    });
  }

  function openForgotPasswordPage() {
    $window.location.href = 'https://cloud.alwayscloseby.com/compatibility/?forgotmypassword';
  }

  function openCompatibilitySite(token) {
    var url = 'https://cloud.alwayscloseby.com/compatibility/';
    if (token) {
      url = url + '?jwt=' + token;
    }

    $window.location.href = url;
  }

  function openAcbUpgradePage(token) {
    $window.location.href = 'https://cloud.alwayscloseby.com/compatibility/upgradeservice/?jwt=' + token;
  }
}