(function () {
    'use strict';
    angular.module('app', ['app.core']);
}());
(function () {
    'use strict';
    angular.module('app.core', [
        /*
     * Angular/Ionic Modules
     */
        'ionic',
        /*
     * App Dependencies
     */
        'restangular',
        'angular-jwt',
        /*
     * Internal Modules
     */
        'utils'
    ]);
}());
(function () {
    'use strict';
    angular.module('utils', []);
}());
(function () {
    'use strict';
    angular.module('app').controller('AccountController', AccountController);
    function AccountController($ionicPopup, $state, userData, loading) {
        var vm = this;
        vm.user = userData;
        vm.password = '';
        vm.save = save;
        function save() {
            if (vm.password)
                vm.user.password = vm.password;
            loading.show();
            vm.user.save().then(function () {
                loading.hide();
                return $ionicPopup.alert({
                    title: 'Success',
                    template: 'Your account has been updated.'
                });
            }).then(function () {
                $state.reload();
            }).catch(function () {
                loading.hide();
                $ionicPopup.alert({
                    title: 'Error',
                    template: 'There was an error updating your account.'
                });
            });
        }
    }
}());
(function () {
    'use strict';
    angular.module('app').config(config);
    function config($stateProvider) {
        $stateProvider.state('menu.account', {
            cache: false,
            url: '/account',
            controller: 'AccountController as vm',
            templateUrl: 'templates/account.html',
            resolve: { userData: userData }
        });
    }
    function userData(auth, user) {
        return user.one(auth.userId).get();
    }
}());
(function () {
    'use strict';
    angular.module('app').factory('auth', auth);
    function auth($http, $window, $q, $state, API, jwtHelper) {
        var service = {
            login: login,
            logout: logout,
            refresh: refresh,
            removeToken: removeToken,
            isAuthenticated: isAuthenticated
        };
        Object.defineProperty(service, 'token', {
            get: getToken,
            set: setToken
        });
        Object.defineProperty(service, 'lastLogin', {
            get: getLastLogin,
            set: setLastLogin
        });
        Object.defineProperty(service, 'userId', { get: getUserId });
        return service;
        function getToken() {
            return $window.localStorage.getItem('accessToken');
        }
        function setToken(token) {
            $window.localStorage.setItem('accessToken', token);
        }
        function removeToken() {
            $window.localStorage.removeItem('accessToken');
        }
        function getLastLogin() {
            return $window.localStorage.getItem('lastLogin');
        }
        function setLastLogin(login) {
            $window.localStorage.setItem('lastLogin', login);
        }
        function getUserId() {
            return parseInt(jwtHelper.decodeToken(this.token).id);
        }
        function login(username, password) {
            if (!localStorageSupported()) {
                return $q.reject({ code: 'private_browsing' });
            }
            return requestToken(username, password).then(setToken).then(function () {
                this.lastLogin = username;
            }.bind(this)).then(testAcbPlus).catch(function (err) {
                err.token = this.token;
                removeToken();
                return $q.reject(err);
            }.bind(this));
        }
        function requestToken(username, password) {
            return $http({
                url: API.url + '/auth',
                method: 'POST',
                skipAuthorization: true,
                timeout: 8000,
                data: {
                    username: username,
                    password: password
                }
            }).then(function (response) {
                return response.data.accessToken;
            }).catch(function (response) {
                var error;
                if (response.data && response.data.error) {
                    error = response.data.error;
                } else {
                    error = { message: 'Could not connect to the server. Please check your connection and try again.' };
                }
                return $q.reject(error);
            });
        }
        function testAcbPlus() {
            return $http.get(API.url + '/auth/test/acbplus').then().catch(function (response) {
                return $q.reject(response.data.error);
            });
        }
        function logout() {
            removeToken();
            $state.go('login');
        }
        function refresh() {
            var self = this;
            return $http({
                url: API.url + '/auth/refresh',
                method: 'GET',
                skipAuthorization: true,
                headers: { Authorization: 'Bearer ' + self.token }
            }).then(function (response) {
                self.token = response.data.accessToken;
                return self.token;
            }).catch(function (response) {
                return $q.reject(response.data.error);
            });
        }
        function isAuthenticated() {
            try {
                return !jwtHelper.isTokenExpired(this.token);
            } catch (e) {
                return false;
            }
        }
        function localStorageSupported() {
            try {
                var testKey = '__alwayscloseby__';
                $window.localStorage.setItem(testKey, testKey);
                $window.localStorage.removeItem(testKey);
            } catch (e) {
                return false;
            }
            return true;
        }
    }
}());
(function () {
    'use strict';
    angular.module('app').config(config);
    function config($httpProvider, jwtInterceptorProvider) {
        jwtInterceptorProvider.tokenGetter = tokenGetter;
        $httpProvider.interceptors.push('jwtInterceptor');
        /* @ngInject */
        function tokenGetter(config, API, auth, jwtHelper) {
            if (config.url.indexOf(API.url) !== 0) {
                return null;
            }
            var token = auth.token;
            if (token) {
                var tokenExp = jwtHelper.getTokenExpirationDate(token);
                var oneHourFromNow = new Date();
                oneHourFromNow.setHours(oneHourFromNow.getHours() + 1);
                if (tokenExp !== null && tokenExp < oneHourFromNow) {
                    return auth.refresh();
                }
            }
            return token;
        }
    }
}());
(function () {
    'use strict';
    angular.module('app').run(config);
    function config($rootScope, $state, auth) {
        /*
   * Whenever the application changes states, check whether
   * the state being transitioned to requires authentication.
   * If the user is not authenticated, redirect them to login
   * and prevent them from accessing that state.
   */
        $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
            if (toState.data && toState.data.authenticate && !auth.isAuthenticated()) {
                event.preventDefault();
                $state.go('login');
            }
            if (toState.name === 'login' && auth.isAuthenticated()) {
                event.preventDefault();
                $state.go('menu.cameras');
            }
        });
        /*
   * angular-jwt will broadcast the "unauthenticated" event
   * whenever $http receives a 401 response. Whenever this
   * event is broadcast, we want to unauthenticate the user
   * and send them back to the login screen.
   */
        $rootScope.$on('unauthenticated', function (event) {
            auth.logout();
        });
    }
}());
(function () {
    'use strict';
    angular.module('app').controller('CameraController', CameraController);
    function CameraController($scope, camrelay) {
        var vm = this;
        vm.toggle = toggle;
        vm.src = camrelay.snapshot(vm.camera.id);
        $scope.$watch('vm.camera.playing', function (newVal) {
            if (newVal) {
                vm.src = camrelay.mjpeg(vm.camera.id);
                vm.notify({ id: vm.camera.id });
            } else {
                vm.src = camrelay.snapshot(vm.camera.id);
            }
        });
        function toggle() {
            vm.camera.playing = !vm.camera.playing;
        }
    }
}());
(function () {
    'use strict';
    angular.module('app').directive('camera', camera);
    function camera() {
        return {
            restrict: 'E',
            scope: {
                camera: '=model',
                notify: '&'
            },
            controller: 'CameraController as vm',
            bindToController: true,
            templateUrl: 'templates/camera.directive.html'
        };
    }
}());
(function () {
    'use strict';
    angular.module('app').controller('CamerasController', CamerasController);
    function CamerasController(cameraCollection, school, auth) {
        var vm = this;
        vm.school = school;
        vm.cameras = cameraCollection;
        vm.notify = notify;
        vm.logout = auth.logout;
        function notify(id) {
            _(vm.cameras).reject('id', id).each(function (camera) {
                camera.playing = false;
            }).commit();
        }
    }
}());
(function () {
    'use strict';
    angular.module('app').factory('cameras', cameras);
    function cameras(user, auth, camrelay) {
        var service = { get: get };
        return service;
        function get(userId) {
            return user.one(userId).getList('cameras').then(function (cameras) {
                return _.map(cameras, function (camera) {
                    return {
                        id: camera.id,
                        title: camera.title,
                        playing: false
                    };
                });
            });
        }
    }
}());
(function () {
    'use strict';
    angular.module('app').config(config);
    function config($stateProvider) {
        $stateProvider.state('menu.cameras', {
            url: '/cameras',
            controller: 'CamerasController as vm',
            templateUrl: 'templates/cameras.html',
            resolve: {
                cameraCollection: cameraCollection,
                school: school
            }
        });
    }
    function cameraCollection(auth, cameras) {
        return cameras.get(auth.userId);
    }
    function school(auth, user) {
        return user.one(auth.userId).customGET('ProSchool');
    }
}());
(function () {
    'use strict';
    angular.module('app').factory('camrelay', camrelay);
    function camrelay(auth) {
        var url = 'https://camrelay.coaunity.com';
        var service = {
            snapshot: snapshot,
            mjpeg: mjpeg
        };
        return service;
        function snapshot(id) {
            return url + '/snapshot/' + id + '?jwt=' + auth.token + '&' + Date.now();
        }
        function mjpeg(id) {
            return url + '/mjpg/' + id + '?jwt=' + auth.token;
        }
    }
}());
(function () {
    'use strict';
    angular.module('app').controller('CancelRequestController', CancelRequestController);
    function CancelRequestController($http, $ionicPopup, API) {
        var vm = this;
        vm.message = '';
        vm.submit = submit;
        function submit() {
            $http.post(API.url + '/acb/cancel-request', { message: vm.message }).then(function () {
                $ionicPopup.alert({
                    title: 'Thank You',
                    template: 'Your request has been submitted.'
                });
                vm.message = '';
            });
        }
    }
}());
(function () {
    'use strict';
    angular.module('app').config(config);
    function config($stateProvider) {
        $stateProvider.state('menu.cancel-request', {
            cache: false,
            url: '/cancel-request',
            controller: 'CancelRequestController as vm',
            templateUrl: 'templates/cancel-request.html'
        });
    }
}());
(function () {
    'use strict';
    angular.module('app').controller('ClassroomChangeController', ClassroomChangeController);
    function ClassroomChangeController($http, $ionicPopup, API) {
        var vm = this;
        vm.message = '';
        vm.submit = submit;
        function submit() {
            $http.post(API.url + '/acb/classroom-change', { message: vm.message }).then(function () {
                $ionicPopup.alert({
                    title: 'Thank You',
                    template: 'Your request has been submitted.'
                });
                vm.message = '';
            });
        }
    }
}());
(function () {
    'use strict';
    angular.module('app').config(config);
    function config($stateProvider) {
        $stateProvider.state('menu.classroom-change', {
            cache: false,
            url: '/classroom-change',
            controller: 'ClassroomChangeController as vm',
            templateUrl: 'templates/classroom-change.html'
        });
    }
}());
(function () {
    'use strict';
    angular.module('app.core').constant('API', {
        url: 'https://api.coaunity.com',
        version: 1
    });
}());
(function () {
    'use strict';
    angular.module('app.core').config(locationConfig).config(restangularConfig).config(scrollConfig).run(loaderConfig);
    function locationConfig($locationProvider) {
        $locationProvider.html5Mode(true);
    }
    function restangularConfig(RestangularProvider, API) {
        RestangularProvider.setBaseUrl(API.url);
    }
    function scrollConfig($ionicConfigProvider) {
        $ionicConfigProvider.scrolling.jsScrolling(false);
    }
    function loaderConfig($rootScope, loading) {
        $rootScope.$on('$stateChangeStart', function () {
            loading.show({ delay: 300 });
        });
    }
}());
(function () {
    'use strict';
    angular.module('app').controller('LoginController', LoginController);
    function LoginController($scope, $window, $state, $ionicHistory, $ionicPopup, auth, loading) {
        var vm = this;
        vm.username = auth.lastLogin || '';
        vm.password = '';
        vm.submit = submit;
        vm.openForgotPasswordPage = openForgotPasswordPage;
        vm.openCompatibilitySite = openCompatibilitySite;
        $scope.$on('$ionicView.enter', clearCache);
        function clearCache() {
            $ionicHistory.clearCache();
        }
        function submit(username, password) {
            loading.show();
            auth.login(username, password).then(function () {
                $state.go('menu.cameras');
            }).catch(function (err) {
                if (err.code === 'invalid_credentials') {
                    displayInvalidCredentialsError(err);
                } else if (err.code === 'acb_plus_not_supported') {
                    openCompatibilitySite(err.token);
                } else if (err.code === 'acb_plus_unauthorized') {
                    displayErrorUnauthorized(err);
                } else if (err.code === 'private_browsing') {
                    displayPrivateBrowsingError(err);
                } else {
                    displayGenericError(err);
                }
            }).finally(loading.hide);
        }
        function displayInvalidCredentialsError(error) {
            $ionicPopup.show({
                title: 'Unable to Login',
                template: error.message,
                buttons: [
                    {
                        text: 'OK',
                        type: [
                            'button-positive',
                            'button-block'
                        ]
                    },
                    {
                        text: 'Forgot Password?',
                        type: [
                            'button-default',
                            'button-block'
                        ],
                        onTap: function (e) {
                            openForgotPasswordPage();
                        }
                    }
                ]
            });
        }
        function displayErrorUnauthorized(error) {
            $ionicPopup.show({
                title: 'Upgrade Alert',
                templateUrl: 'templates/acb-plus-unauthorized.html',
                buttons: [
                    {
                        text: 'Learn More',
                        type: [
                            'button-positive',
                            'button-block'
                        ],
                        onTap: function (e) {
                            openAcbUpgradePage(error.token);
                        }
                    },
                    {
                        text: 'Maybe Later',
                        type: [
                            'button-stable',
                            'button-block'
                        ],
                        onTap: function (e) {
                            openCompatibilitySite(error.token);
                        }
                    }
                ]
            });
        }
        function displayPrivateBrowsingError(error) {
            var platform = ionic.Platform.platform();
            var helpLinks = {
                ios: 'https://support.apple.com/en-us/HT203036',
                macintel: 'https://support.apple.com/kb/PH19216?locale=en_US'
            };
            var buttons = [{
                    text: 'OK',
                    type: [
                        'button-block',
                        'button-positive'
                    ]
                }];
            if (_.contains(Object.keys(helpLinks), platform)) {
                buttons.unshift({
                    text: 'How do I turn off Private Browsing?',
                    type: [
                        'button-block',
                        'button-stable'
                    ],
                    onTap: function (e) {
                        $window.open(helpLinks[platform], '_blank');
                    }
                });
            }
            $ionicPopup.show({
                title: 'Private Browsing Alert',
                templateUrl: 'templates/private-browsing-error.html',
                buttons: buttons
            });
        }
        function displayGenericError(error) {
            $ionicPopup.alert({
                title: 'Unable to Login',
                template: error.message,
                okType: [
                    'button-block',
                    'button-positive'
                ]
            });
        }
        function openForgotPasswordPage() {
            $window.location.href = 'https://cloud.alwayscloseby.com/compatibility/?forgotmypassword';
        }
        function openCompatibilitySite(token) {
            var url = 'https://cloud.alwayscloseby.com/compatibility/';
            if (token) {
                url = url + '?jwt=' + token;
            }
            $window.location.href = url;
        }
        function openAcbUpgradePage(token) {
            $window.location.href = 'https://cloud.alwayscloseby.com/compatibility/upgradeservice/?jwt=' + token;
        }
    }
}());
(function () {
    'use strict';
    angular.module('app').config(config);
    function config($stateProvider, $urlRouterProvider) {
        $urlRouterProvider.otherwise(function ($injector) {
            var $state = $injector.get('$state');
            $state.go('login');
        });
        $stateProvider.state('login', {
            cache: false,
            url: '/login',
            controller: 'LoginController as vm',
            templateUrl: 'templates/login.html'
        });
    }
}());
(function () {
    'use strict';
    angular.module('app').controller('MenuController', MenuController);
    function MenuController($state, auth) {
        var vm = this;
        vm.logout = auth.logout;
    }
}());
(function () {
    'use strict';
    angular.module('app').config(config);
    function config($stateProvider) {
        $stateProvider.state('menu', {
            abstract: true,
            controller: 'MenuController as vm',
            templateUrl: 'templates/menu.html',
            data: { authenticate: true }
        });
    }
}());
(function () {
    'use strict';
    angular.module('app').controller('ReportIssueController', ReportIssueController);
    function ReportIssueController($http, $ionicPopup, API) {
        var vm = this;
        vm.message = '';
        vm.submit = submit;
        function submit() {
            $http.post(API.url + '/acb/report-issue', { message: vm.message }).then(function () {
                $ionicPopup.alert({
                    title: 'Thank You',
                    template: 'Your report has been submitted.'
                });
                vm.message = '';
            });
        }
    }
}());
(function () {
    'use strict';
    angular.module('app').config(config);
    function config($stateProvider) {
        $stateProvider.state('menu.report-issue', {
            cache: false,
            url: '/report-issue',
            controller: 'ReportIssueController as vm',
            templateUrl: 'templates/report-issue.html'
        });
    }
}());
(function () {
    'use strict';
    angular.module('app').factory('user', user);
    function user(Restangular) {
        return Restangular.service('AcbRegistration');
    }
}());
(function () {
    'use strict';
    angular.module('utils').directive('autoFocus', autoFocus);
    function autoFocus($timeout) {
        return {
            restrict: 'A',
            link: link
        };
        function link(scope, element, attrs) {
            $timeout(function () {
                element[0].focus();
            }, 400);
        }
    }
}());
(function () {
    'use strict';
    angular.module('utils').service('loading', loading);
    function loading($ionicLoading) {
        var service = {
            show: show,
            hide: hide
        };
        return service;
        function show(opts) {
            var defaultOpts = {
                templateUrl: 'templates/loading.html',
                noBackdrop: true,
                hideOnStateChange: true
            };
            opts = angular.extend({}, defaultOpts, opts);
            $ionicLoading.show(opts);
        }
        function hide() {
            $ionicLoading.hide();
        }
    }
}());