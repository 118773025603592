angular
  .module('app')
  .config(config);

function config($stateProvider, $urlRouterProvider) {
  $urlRouterProvider.otherwise(function($injector) {
    var $state = $injector.get('$state');
    $state.go('login');
  });

  $stateProvider.state('login', {
    cache: false,
    url: '/login',
    controller: 'LoginController as vm',
    templateUrl: 'templates/login.html'
  });
}
