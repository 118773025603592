angular
  .module('app')
  .factory('camrelay', camrelay);

function camrelay(auth) {
  var url = "https://camrelay.coaunity.com";
  var service = {
    snapshot: snapshot,
    mjpeg: mjpeg
  };
  return service;


  function snapshot(id) {
    return url + "/snapshot/" + id + "?jwt=" + auth.token + '&' + Date.now();
  }

  function mjpeg(id) {
    return url + "/mjpg/" + id + "?jwt=" + auth.token;
  }
}