angular
  .module('app')
  .config(config);

function config($stateProvider) {
  $stateProvider.state('menu.account', {
    cache: false,
    url: '/account',
    controller: 'AccountController as vm',
    templateUrl: 'templates/account.html',
    resolve: {
      userData: userData
    }
  });
}

function userData(auth, user) {
  return user.one(auth.userId).get();
}