angular
  .module('app')
  .config(config);

function config($stateProvider) {
  $stateProvider.state('menu.classroom-change', {
    cache: false,
    url: '/classroom-change',
    controller: 'ClassroomChangeController as vm',
    templateUrl: 'templates/classroom-change.html'
  });
}