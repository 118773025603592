angular
  .module('app')
  .directive('camera', camera);

function camera() {
  return {
    restrict: 'E',
    scope: {
      camera: '=model',
      notify: '&'
    },
    controller: 'CameraController as vm',
    bindToController: true,
    templateUrl: 'templates/camera.directive.html'
  };
}