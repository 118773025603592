angular
  .module('utils')
  .directive('autoFocus', autoFocus);

function autoFocus($timeout) {
  return {
    restrict: 'A',
    link: link
  };

  function link(scope, element, attrs) {
    $timeout(function() {
      element[0].focus();
    }, 400);
  }
}