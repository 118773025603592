angular
  .module('app')
  .controller('CamerasController', CamerasController);

function CamerasController(cameraCollection, school, auth) {
  var vm = this;
  vm.school = school;
  vm.cameras = cameraCollection;
  vm.notify = notify;
  vm.logout = auth.logout;

  function notify(id) {
    _(vm.cameras)
      .reject('id', id)
      .each(function(camera){
        camera.playing = false;
      })
      .commit();
  }
}