angular
  .module('app')
  .run(config);

function config($rootScope, $state, auth) {
  /*
   * Whenever the application changes states, check whether
   * the state being transitioned to requires authentication.
   * If the user is not authenticated, redirect them to login
   * and prevent them from accessing that state.
   */
  $rootScope.$on("$stateChangeStart", function(event, toState, toParams, fromState, fromParams) {
    if (toState.data && toState.data.authenticate && !auth.isAuthenticated()) {
      event.preventDefault();
      $state.go("login");
    }

    if (toState.name === 'login' && auth.isAuthenticated()) {
      event.preventDefault();
      $state.go("menu.cameras");
    }
  });

  /*
   * angular-jwt will broadcast the "unauthenticated" event
   * whenever $http receives a 401 response. Whenever this
   * event is broadcast, we want to unauthenticate the user
   * and send them back to the login screen.
   */
  $rootScope.$on("unauthenticated", function(event) {
    auth.logout();
  });
}