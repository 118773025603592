angular
  .module('app')
  .config(config);

function config($httpProvider, jwtInterceptorProvider) {
  jwtInterceptorProvider.tokenGetter = tokenGetter;
  $httpProvider.interceptors.push('jwtInterceptor');

  /* @ngInject */
  function tokenGetter(config, API, auth, jwtHelper) {
    if (config.url.indexOf(API.url) !== 0) {
      return null;
    }

    var token = auth.token;
    if (token) {
      var tokenExp = jwtHelper.getTokenExpirationDate(token);
      var oneHourFromNow = new Date();
      oneHourFromNow.setHours(oneHourFromNow.getHours() + 1);
      if (tokenExp !== null && tokenExp < oneHourFromNow) {
        return auth.refresh();
      }
    }

    return token;
  }
}