angular
  .module('app')
  .controller('CancelRequestController', CancelRequestController);

function CancelRequestController($http, $ionicPopup, API) {
  var vm = this;
  vm.message = '';
  vm.submit = submit;

  function submit() {
    $http.post(API.url + '/acb/cancel-request', {
      message: vm.message
    })
    .then(function() {
      $ionicPopup.alert({
        title: "Thank You",
        template: "Your request has been submitted."
      });
      vm.message = '';
    });
  }
}