angular
  .module('app')
  .controller('ClassroomChangeController', ClassroomChangeController);

function ClassroomChangeController($http, $ionicPopup, API) {
  var vm = this;
  vm.message = '';
  vm.submit = submit;

  function submit() {
    $http.post(API.url + '/acb/classroom-change', {
      message: vm.message
    })
    .then(function() {
      $ionicPopup.alert({
        title: "Thank You",
        template: "Your request has been submitted."
      });
      vm.message = '';
    });
  }
}