angular
  .module('app.core')
  .config(locationConfig)
  .config(restangularConfig)
  .config(scrollConfig)
  .run(loaderConfig);

function locationConfig($locationProvider) {
  $locationProvider.html5Mode(true);
}

function restangularConfig(RestangularProvider, API) {
  RestangularProvider.setBaseUrl(API.url);
}

function scrollConfig($ionicConfigProvider) {
  $ionicConfigProvider.scrolling.jsScrolling(false);
}

function loaderConfig($rootScope, loading) {
  $rootScope.$on('$stateChangeStart', function(){
    loading.show({ delay: 300 });
  });
}
