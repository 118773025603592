angular
  .module('app')
  .config(config);

function config($stateProvider) {
  $stateProvider.state('menu', {
    abstract: true,
    controller: 'MenuController as vm',
    templateUrl: 'templates/menu.html',
    data: { authenticate: true }
  });
}