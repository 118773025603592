angular
  .module('utils')
  .service('loading', loading);

function loading($ionicLoading) {
  var service = {
    show: show,
    hide: hide
  };
  return service;

  function show(opts) {
    var defaultOpts = {
      templateUrl: 'templates/loading.html',
      noBackdrop: true,
      hideOnStateChange: true
    };

    opts = angular.extend({}, defaultOpts, opts);

    $ionicLoading.show(opts);
  }

  function hide() {
    $ionicLoading.hide();
  }
}