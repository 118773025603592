angular
  .module('app')
  .config(config);

function config($stateProvider) {
  $stateProvider.state('menu.cameras', {
    url: '/cameras',
    controller: 'CamerasController as vm',
    templateUrl: 'templates/cameras.html',
    resolve: {
      cameraCollection: cameraCollection,
      school: school
    }
  });
}

function cameraCollection(auth, cameras) {
  return cameras.get(auth.userId);
}

function school(auth, user) {
  return user
    .one(auth.userId)
    .customGET('ProSchool');
}