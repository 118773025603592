angular
  .module('app')
  .factory('cameras', cameras);

function cameras(user, auth, camrelay) {
  var service = {
    get: get
  };
  return service;

  function get(userId) {
    return user
      .one(userId)
      .getList('cameras')
      .then(function(cameras) {
        return _.map(cameras, function(camera) {
          return {
            id: camera.id,
            title: camera.title,
            playing: false
          };
        });
      });
  }
}