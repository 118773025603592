angular
  .module('app')
  .factory('auth', auth);

function auth($http, $window, $q, $state, API, jwtHelper) {
  var service = {
    login: login,
    logout: logout,
    refresh: refresh,
    removeToken: removeToken,
    isAuthenticated: isAuthenticated
  };
  Object.defineProperty(service, 'token', {
    get: getToken,
    set: setToken
  });
  Object.defineProperty(service, 'lastLogin', {
    get: getLastLogin,
    set: setLastLogin
  });
  Object.defineProperty(service, 'userId', {
    get: getUserId,
  });
  return service;

  function getToken() {
    return $window.localStorage.getItem('accessToken');
  }

  function setToken(token) {
    $window.localStorage.setItem('accessToken', token);
  }

  function removeToken() {
    $window.localStorage.removeItem('accessToken');
  }

  function getLastLogin() {
    return $window.localStorage.getItem('lastLogin');
  }

  function setLastLogin(login) {
    $window.localStorage.setItem('lastLogin', login);
  }

  function getUserId() {
    return parseInt(jwtHelper.decodeToken(this.token).id);
  }

  function login(username, password) {
    if (!localStorageSupported()) {
      return $q.reject({ code: "private_browsing" });
    }

    return requestToken(username, password)
      .then(setToken)
      .then(function() {
        this.lastLogin = username;
      }.bind(this))
      .then(testAcbPlus)
      .catch(function(err) {
        err.token = this.token;
        removeToken();
        return $q.reject(err);
      }.bind(this));
  }

  function requestToken(username, password) {
    return $http({
        url: API.url + '/auth',
        method: 'POST',
        skipAuthorization: true,
        timeout: 8000,
        data: { username: username, password: password }
      })
      .then(function(response) {
        return response.data.accessToken;
      })
      .catch(function(response) {
        var error;
        if (response.data && response.data.error) {
          error = response.data.error;
        } else {
          error = {
            message: "Could not connect to the server. Please check your connection and try again."
          };
        }
        return $q.reject(error);
      });
  }

  function testAcbPlus() {
    return $http.get(API.url + '/auth/test/acbplus')
      .then()
      .catch(function(response) {
        return $q.reject(response.data.error);
      });
  }

  function logout() {
    removeToken();
    $state.go('login');
  }

  function refresh() {
    var self = this;

    return $http({
      url: API.url + '/auth/refresh',
      method: 'GET',
      skipAuthorization: true,
      headers: { Authorization: 'Bearer ' + self.token }
    }).then(function(response) {
      self.token = response.data.accessToken;
      return self.token;
    }).catch(function(response){
      return $q.reject(response.data.error);
    });
  }

  function isAuthenticated() {
    try {
      return !jwtHelper.isTokenExpired(this.token);
    } catch (e) {
      return false;
    }
  }

  function localStorageSupported() {
    try {
      var testKey = '__alwayscloseby__';
      $window.localStorage.setItem(testKey, testKey);
      $window.localStorage.removeItem(testKey);
    } catch(e) {
      return false;
    }

    return true;
  }
}