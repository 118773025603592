angular
  .module('app')
  .config(config);

function config($stateProvider) {
  $stateProvider.state('menu.cancel-request', {
    cache: false,
    url: '/cancel-request',
    controller: 'CancelRequestController as vm',
    templateUrl: 'templates/cancel-request.html'
  });
}