angular
  .module('app')
  .config(config);

function config($stateProvider) {
  $stateProvider.state('menu.report-issue', {
    cache: false,
    url: '/report-issue',
    controller: 'ReportIssueController as vm',
    templateUrl: 'templates/report-issue.html'
  });
}