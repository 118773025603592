angular
  .module('app.core', [
    /*
     * Angular/Ionic Modules
     */
    'ionic',

    /*
     * App Dependencies
     */
    'restangular',
    'angular-jwt',

    /*
     * Internal Modules
     */
    'utils'
  ]);