angular
  .module('app')
  .controller('CameraController', CameraController);

function CameraController($scope, camrelay) {
  var vm = this;
  vm.toggle = toggle;
  vm.src = camrelay.snapshot(vm.camera.id);

  $scope.$watch('vm.camera.playing', function(newVal) {
    if (newVal) {
      vm.src = camrelay.mjpeg(vm.camera.id);
      vm.notify({ id: vm.camera.id });
    } else {
      vm.src = camrelay.snapshot(vm.camera.id);
    }
  });

  function toggle() {
    vm.camera.playing = !vm.camera.playing;
  }
}